
import { DateTime } from "luxon";
import * as fflate from "fflate";

// Application Specific
import PlanningBoard from "../models/planningBoardModel";
import { BaseConfig } from "../baseConfig";
import Driver from "../models/driverModel";
import Trailer from "../models/trailerModel";
import Config from '../Config.json';
import axios from 'axios';

export const getDriverbyTerminalIDs = async (request_data) => {
  try {
    let data = {
      terminal_id: request_data.terminals,
      date: request_data.date,
    };

    const url = BaseConfig.ApiEndPoint + "/planningboarddrivers";

    const driverApiRes = await axios.post(url, data);
    const driverApiResponse = atob(driverApiRes.data);

    // Convert binary string to character-number array
    const charData = driverApiResponse.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let driverApiDataUnCompressed = fflate.unzlibSync(binData);
    let driverApiDataUnCompressedString = fflate.strFromU8(
      driverApiDataUnCompressed
    );
    const driverApiDecompressedObject = JSON.parse(
      driverApiDataUnCompressedString
    );

    const driver = new Driver();

    return driverApiDecompressedObject.data;
  } catch (err) {
    throw err;
  }
};

export const getBoardByDriverFormatted = async (
  movementData,
  trailerData,
  driverData,
  plannedMode = true

) => {
  let planners = [];

  try {
    planners = await Promise.all(
      driverData.map(async (dri_data) => {
        const block = {};
        const driver_movements = movementData?.data?.filter(
          (mvmt) => mvmt?.driver_id === dri_data?.driver_id
        );

        block.driverId = dri_data.driver_id;
        block.driver_full_name = dri_data.driver_full_name;
        block.driver_name=dri_data.driver_name
        block.passport_date = dri_data.passport_date;
        block.passport_card = dri_data.passport_card;
        block.enhanced_license = dri_data.enhanced_license;
        block.hazmat_endorsement = dri_data.hazmat_endorsement;
        block.hazmat_certified_exp_date = dri_data.hazmat_certified_exp_date;
        block.hazmat_certified = dri_data.hazmat_certified;
        block.hm126 = dri_data.hm126;
        block.hm126expdate = dri_data.hm126expdate;
        block.respirator_due = dri_data.respirator_due;
        block.latitude = dri_data.latitude;
        block.longitude = dri_data.longitude;
        block.last_coordinate_update_time = dri_data.last_coordinate_update_time;

        block.driver_full_name = dri_data.driver_full_name;
        block.samsara_id = dri_data.samsara_id;
        block.driver_type_class = dri_data.driver_type_class;
        block.hire_date = dri_data.hire_date;
        block.terminal = dri_data.terminal;
        block.terminal_city = dri_data.termina_full_name;
        block.schedule = {}
        block.hosclock = dri_data.hosclock ? dri_data.hosclock : {};
        block.utilization = 0;
        block.messages=dri_data.messages;
        block.ScheduleOverrideList = dri_data.ScheduleOverrideList;
        block.current_tractor = dri_data.current_tractor
        block.driver_type = dri_data.equipment_type_id

        try {
          block.movements = getPlanningBoardDriverMovements({
            driver_movements: driver_movements,
            plannedMode: plannedMode,
            dri_data
          });

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

          // trim mvmt/stop blocks
          block.movements = block.movements
            .map((mvmt, idx) => {
              if (idx >= block.movements.length - 1) return mvmt;
              let next = idx;
              while (
                next < block.movements.length - 1 &&
                block.movements[next].startTime ==
                block.movements[idx].startTime
              )
                next += 1;
              return {
                ...mvmt,
                endTime: Math.min(
                  mvmt.endTime,
                  block.movements[next]?.startTime
                ),
              };
            })
            .filter((mvmt) => mvmt.startTime <= mvmt.endTime);

          // dedupe
          block.movements = [
            ...block.movements.filter((it) => it?.stopData == null),
            ...[
              ...new Set(
                block.movements
                  .filter((it) => it?.stopData != null)
                  .map((it) => it?.stopData?.lm_stop_id)
              ),
            ].map((stop_id) => {
              let stops = block.movements.filter(
                (it) => it?.stopData?.lm_stop_id == stop_id
              );
              if (stops.length == 1) return stops[0];
              return stops.filter(
                (stop) => stop.movementData.pickup.lm_stop_id == stop_id
              )[0];
            }),
          ].filter((m) => m);

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

          // Add linked mvmts to stops
          block.movements = block.movements.map((mvmt, idx) => {
            if (mvmt.stopData) {
              let linkedMvmts = [
                idx > 0
                  ? {
                    move_id: block.movements[idx - 1]?.move_id,
                    type: block.movements[idx - 1]?.movementData
                      ?.movement_type,
                        status:block.movements[idx-1].movementData?.status
                  }
                  : null,
                idx < block.movements.length - 1
                  ? {
                    move_id: block.movements[idx + 1]?.move_id,
                    type: block.movements[idx + 1]?.movementData
                      ?.movement_type,
                        status:block.movements[idx+1].movementData?.status
                  }
                  : null,
              ].filter((i) => i);

              return {
                ...mvmt,
                stopData: {
                  ...mvmt.stopData,
                  linkedMvmts,
                },
              };
            }
            return mvmt;
          });

          let orders = [];
          let trailers = [];
          let final_shifts = [];

          for (let i = 0; i < block.movements.length; i++) {
            let it = block.movements[i];
            if (
              !(
                [null, "", undefined].includes(
                  ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                    ["", null, undefined].includes(it.orderId2)
                    ? it.orderId
                    : it.orderId2
                ) || ["SS", "ES", "BT", "E", "HT", "DT","DH"].includes(it.type)
              )
            ) {
              let order = {};
              let movementData = it.movementData;

              order.orderId =
                ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                  ["", null, undefined].includes(it.orderId2)
                  ? it.orderId
                  : it.orderId2;
              order.mvmtType = it.type;
              order.terminal_id = it.terminal_id;
              order.orderStartTime = it.startTime;
              order.orderEndTime = it.endTime;
              order.commodity = it.commodity;
              order.status = it.status;
              order.bol_received = it.bol;
              order.ordered_wt = it.weight;
              order.pickup_location = movementData.pickup.location;
              order.delivery_location = movementData.delivery.location;
              order.terminalId = movementData?.driver?.terminal_id;
              order.order_status = it.status;
              order.location_name = movementData.pickup.location.location_name;
              order.address1 = movementData.pickup.location.address1;
              order.city = movementData.pickup.location.city;
              order.state = movementData.pickup.location.state;
              order.zip = movementData.pickup.location.zip;
              order.move_id = movementData.load_id;
              order.company_location_id1 =
                movementData.delivery.location.company_location_id;
              order.location_name1 =
                movementData.delivery.location.location_name;
              order.address11 = movementData.delivery.location.address1;
              order.city1 = movementData.delivery.location.city;
              order.plan_id = movementData.delivery.plan_id;
              order.state1 = movementData.delivery.location.state;
              order.zip1 = movementData.delivery.location.zip;
              order.delivery_driver_load_unload =
                movementData.delivery.driver_load_unload;
              order.pickup_driver_load_unload =
                movementData.pickup.driver_load_unload;
              order.pickup_appt_required = movementData.pickup.appt_required;
              order.picup_confirmed = movementData.pickup.confirmed;
              order.pickup_sched_arrive_early_utc =
                movementData.pickup.sched_arrive_early_utc;
              order.pickup_sched_arrive_late_utc =
                movementData.pickup.sched_arrive_late_utc;
              order.delivery_sched_arrive_early_utc =
                movementData.delivery.sched_arrive_early_utc;
              order.delivery_sched_arrive_late_utc =
                movementData.delivery.sched_arrive_late_utc;
              order.distance = movementData.distance;
              order.pickup_timezone = movementData.pickup.pickup_timezone;
              order.delivery_timezone = movementData.delivery.delivery_timezone;
              order.commoditygroupid = it.order_commodity?.commoditygroupid
                ? it.order_commodity?.commoditygroupid
                : "";
              order.commoditygroup_description = it.order_commodity?.description
                ? it.order_commodity?.description
                : "";
              order.actual_arrival_utc = movementData.pickup.actual_arrival_utc;
              order.eta_arrival_utc = movementData.pickup.eta_arrival_utc;
              order.eta_departure_utc = movementData.pickup.eta_departure_utc;
              order.actual_departure_utc =
                movementData.pickup.actual_departure_utc;
              order.actual_arrival_utc1 =
                movementData.delivery.actual_arrival_utc;
              order.actual_departure_utc1 =
                movementData.delivery.actual_departure_utc;
              order.eta_arrival_utc1 =
                movementData.delivery.eta_arrival_utc;
              order.eta_departure_utc1 =
                movementData.delivery.eta_departure_utc;

              order.style = {};

              if ([0, null, undefined, ""].includes(movementData.xmitted2driver_utc))
                order.style = { backgroundColor: "white", color: "grey" };
              else {
                if (
                  [0, null, undefined, "",'N'].includes(movementData.xmit_accepted)
                ){
                  order.style = { backgroundColor: "orange", color: "white" };
                }
                else {
                  console.log({message: 'color', order});
                  order.style = { backgroundColor: "green", color: "white" };
                }
              }

              orders.push(order);
            }
            
            if (it.trailer_id && it.trailer_id !== null) {
              //console.log("trailerDatas1",it)
              const trailerDatas = trailerData && trailerData?.find(t => t.Trailer_ID == it.trailer_id)
              //console.log("trailerDatas",trailerData)
              let trailer = {
                /// order_id: it.orderId,
                order_id2: it.orderId2,
                trailer_id: it.trailer_id,
                mvmt_load_ids: it.move_id,
                trailerStartTime: it.startTime,
                trailerEndTime: it.endTime,
                license_plate: it?.trailerData?.license_plate,
                license_state: it?.trailerData?.license_state,
                stat: it.trailerData?.stat,
                make: it.trailerData?.make,
                model: it.trailerData?.model,
                model_year: it.trailerData?.model_year,
                tare_weight: it.trailerData?.tare_weight,
                pm_due_date_utc: it.trailerData?.pm_due_date_utc,
                commodity_group: it.trailerData?.commodity_group,
                commoditygroup_description:
                  it.trailerData?.commoditygroup?.description,
                terminal_id: it.terminal_id,
                // terminalname:it.stopData?.location?.city ,
                // commodityid:it.movementData?.order?.order_commodity[0]?.CG?.commodityid,
                // commodity_Id:it.movementData?.order?.order_commodity[0]?.commodity_id,
                equip_types: trailerDatas?.Equip_Type,
                mvmt_type: it.type,
                Last_Wash_WO : trailerDatas?.['Last_Wash_W/O'],
                Loads_to_Next_Wash : trailerDatas?.Loads_to_Next_Wash,
                Days_to_Next_Wash : trailerDatas?.Days_to_Next_Wash,
                Last_Order : trailerDatas?.Last_Order,
                Last_Commodity : trailerDatas?.Last_Commodity,
                Last_Product : trailerDatas?.Last_Product,
                Last_Washed : trailerDatas?.Last_Washed,
                commodity_group : trailerDatas?.Commodity_Group,
                Loads_Since_Last_Wash : trailerDatas?.Loads_Since_Last_Wash,
                Days_Since_Last_Wash : trailerDatas?.Days_Since_Last_Wash,
                Length : trailerDatas?.Length,
                Width : trailerDatas?.Width,
                Height : trailerDatas?.Height,
                Volume : trailerDatas?.Volume,
                dedicated : trailerDatas?.Dedicated,
                terminal : trailerDatas?.Terminal,
                shipper_pool : trailerDatas?.Shipper_Pool,
                State : trailerDatas?.State,

              };

              trailers.push(trailer);
            }

            if (["SS", "ES"].includes(it.type))
              final_shifts.push({
                shiftType: it.type === "SS" ? "start" : "end",
                shiftStartTime: it.startTime,
                shiftEndTime: it.endTime,
                move_id: it.move_id,
                movementData: it.movementData,
              });
          }

          let final_orders = [];
          let final_trailers = [];

          let idxs = [];
          orders.sort((a, b) => a.startTime - b.startTime);
          orders.forEach((it, idx) => {
            if (idxs.includes(idx)) return;
            let order = { ...it };
            let obstacle = false;

            orders.slice(idx + 1).forEach((it2, idx2) => {
              if (
                obstacle ||
                ((it2.orderId != order.orderId &&
                  it2.orderStartTime != order.orderStartTime) ||
                  (["SS", "ES", "BT", "E", "HT", "DT"].includes(it2.mvmtType) &&
                    Math.abs(it2.orderEndTime - it2.orderStartTime) > 0)) &&
                !["DEL", "SD"].includes(it2.mvmtType) ||
                order.orderEndTime < it2.orderStartTime
              ) {
                obstacle = true;
                return;
              } else {
                idxs.push(idx2 + idx + 1);
                if ([null, undefined, ""].includes(order.orderId)) order.orderId = it2.orderId;
                order.orderEndTime = it2.orderEndTime;
              }
            });

            final_orders.push(order);
            idxs.push(idx);
          });

          idxs = [];

          trailers.sort((a, b) => a.trailerStartTime - b.trailerStartTime);
          trailers.forEach((it, idx) => {
            if (idxs.includes(idx)) return;
            let trailer = {
              ...it,
              mvmt_load_ids: [],
              all_orders: [],
            };
            let obstacle = false;
            trailers.slice(idx).forEach((it2, idx2) => {
              if (
                (obstacle ||
                  (((it2.trailer_id != it.trailer_id &&
                    it2.order_id2 != it.order_id2) ||
                    (["SS", "ES", "BT"].includes(it2.mvmt_type) &&
                      Math.abs(it2.trailerEndTime - it2.trailerStartTime) >
                      0) ||
                    trailer.trailerEndTime < it2.trailerStartTime ||
                    (it2.order_id2 != it.order_id2 &&
                      (it.trailer_id == "" || it2.trailer_id == ""))) &&
                    !["DT", "DEL", "SD"].includes(it2.mvmt_type))) &&
                it2.trailerStartTime != it.trailerStartTime
              ) {
                obstacle = true;
                return;
              } else {
                if (["", null, undefined].includes(trailer.trailer_id))
                  trailer.trailer_id = it2.trailer_id;
                idxs.push(idx2 + idx);
                trailer.mvmt_load_ids.push(it2.mvmt_load_ids);
                trailer.all_orders.push(it2.order_id);
                trailer.trailerEndTime = it2.trailerEndTime;
              }
            });
            trailer.mvmt_load_ids = [...new Set(trailer.mvmt_load_ids)];
            trailer.all_orders = [...new Set(trailer.all_orders)];
            final_trailers.push(trailer);
            idxs.push(idx);
          });

          let newEmptyMoveByPlanner=[];
          block.movements?.forEach((movement)=>{
            if(movement?.type=='E' && movement?.movementData?.is_planner_inserted){
              newEmptyMoveByPlanner.push(movement)
            }
          })
          block.orders = final_orders;
          block.trailers = final_trailers;
          block.shifts = final_shifts;
          block.newEmptyMoveByPlanner=newEmptyMoveByPlanner;
          block.movements = block.movements.filter(
            (mvmt) => !["SS", "ES"].includes(mvmt.type)
          );
          let driverOffduty=[];
          if (plannedMode){
            let endShits=block.movements?.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null).sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);
            endShits.length>0 && endShits?.forEach((el)=>{
              let obj={
                logStartTime:el.movementData.delivery.planned_depart_utc,
                logEndTime:el.movementData.delivery.planned_depart_utc + 10 * 60 * 60 * 1000
              }
              driverOffduty.push(obj)
            })
          }else{
            let endShits=block.movements?.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null).sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);
          endShits.length>0 && endShits?.forEach((el)=>{
            let obj={
              logStartTime:el.movementData.delivery.eta_departure_utc,
              logEndTime:el.movementData.delivery.eta_departure_utc + 10 * 60 * 60 * 1000
            }
            driverOffduty.push(obj)
          })
          }
          
          block.schedule.offduty=driverOffduty
          block.isloading = false;
        } catch (err) {
          console.log(err);
        }
        return block;
      })
    );


  } catch (err) {
    console.log({err});
    return [];
  }
  return planners;
};

export const getPlanningBoardDriverMovements = ({
  driver_movements,
  plannedMode = true,
  driverData
}) => {
  try {
    let movements = [];
    if (!driver_movements) return movements;

    for (let i = 0; i < driver_movements.length; i++) {
      let mvmt = driver_movements[i];

      let pu_arrive_utc = plannedMode
        ? mvmt.pickup.planned_arrive_utc
        : mvmt.pickup.eta_arrival_utc;

      let pu_depart_utc = plannedMode
        ? mvmt.pickup.planned_depart_utc
        : mvmt.pickup.eta_departure_utc;
      let del_arrive_utc = plannedMode
        ? mvmt.delivery.planned_arrive_utc
        : mvmt.delivery.eta_arrival_utc;
      let del_depart_utc = plannedMode
        ? mvmt.delivery.planned_depart_utc
        : mvmt.delivery.eta_departure_utc;

      if (
        [0, null, undefined].includes(pu_arrive_utc) ||
        [0, null, undefined].includes(pu_depart_utc) ||
        [0, null, undefined].includes(del_arrive_utc) ||
        [0, null, undefined].includes(del_depart_utc)
      )
        continue;
      let pickup_style = {};

      // if (mvmt.pickup.eta_utc > mvmt.pickup.sched_arrive_late_utc) {

      //   if (!mvmt.pickup.sched_arrive_late_utc) {
      //     pickup_style = {
      //       background: "#5eab5e",
      //       color: "white",
      //     }
      //   } else {
      //     pickup_style = {
      //       background: "#b53329",
      //       color: "white",
      //     }
      //   }
      // } else if ([0, null, undefined, ""].includes(mvmt.pickup.eta_utc)) {
      //   pickup_style = {
      //     background: "#5eab5e",
      //     color: "white",
      //   };
      // } else if (
      //   mvmt.pickup.eta_utc > pu_arrive_utc &&
      //   mvmt.pickup.eta_utc <= mvmt.pickup.sched_arrive_late_utc
      // ) {
      //   pickup_style = { background: "#c9af31", color: "white" };
      // } else if (mvmt.pickup.eta_utc <= pu_arrive_utc) {
      //   pickup_style = { background: "#5eab5e", color: "white" };
      // }

       if (mvmt.pickup.eta_arrival_utc > mvmt.pickup.sched_arrive_late_utc && (mvmt.pickup.stop_type == 'PU' || mvmt.pickup.stop_type == 'DEL')) {
        pickup_style = {
          background: "#b53329", // Red
          color: "white",
        };
      }else if (mvmt.pickup.eta_arrival_utc > mvmt.pickup.planned_arrive_utc) {
        pickup_style = {
          background: "#c9af31", // Yellow
          color: "white",
        }
      }else {
        pickup_style = {
          background: "#5eab5e", // Green
          color: "white",
        };
      }


      let pu = {};
      // if(i-1>= 0){
      //   pu.associated_move_ids = [driver_movements[i-1]?.load_id,mvmt.load_id]
      // }

      pu.id = mvmt.pickup.lm_stop_id;

      pu.orderId = mvmt.order_id;
      pu.orderId2 = mvmt.order_id2;

      pu.move_id = mvmt.load_id;
      pu.load_id = mvmt.load_id;
      pu.movement_type = mvmt.movement_type;
      pu.movementData = mvmt;
      pu.stopData = {
        ...mvmt.pickup,
      };
      pu.type =
        mvmt.pickup.stop_type == "split pickup"
          ? "SP"
          : mvmt.pickup.stop_type == "split delivery"
            ? "SD"
            : mvmt.pickup.stop_type;
      pu.stop_pickup_type = mvmt.pickup.stop_type;
      pu.trailer_id = ["SS", "ES", "BT"].includes(mvmt.pickup.stop_type)
        ? null
        : mvmt.trailer_id ?? "";
      pu.orig_trailer_id = mvmt.trailer_id;
      pu.movement_status = mvmt.status;
      pu.trailerData = mvmt.trailer;
      pu.terminal_id = mvmt.terminal_id;
      pu.startTime = pu_arrive_utc;
      pu.endTime = pu_depart_utc;
      pu.pickup_timezone = mvmt.pickup.pickup_timezone;
      pu.pickup_planned_arrive = mvmt.pickup.planned_arrive_utc;
      pu.pickup_planned_depart = mvmt.pickup.planned_depart_utc;
      pu.pickup_sched_arrive_early_utc = mvmt.pickup.sched_arrive_early_utc;
      pu.pickup_sched_arrive_late_utc = mvmt.pickup.sched_arrive_late_utc;
      pu.pickup_actual_arrive = mvmt.pickup.actual_arrival_utc;
      pu.pickup_actual_depart = mvmt.pickup.actual_departure_utc;
      pu.pickup_eta_utc = mvmt.pickup.eta_arrival_utc;
      pu.pickup_eta_arrive = mvmt.pickup.eta_arrival_utc;
      pu.pickup_eta_depart = mvmt.pickup.eta_departure_utc;
      pu.driverId = mvmt.driver_id;
      pu.latitude = mvmt.pickup?.latitude;
      pu.longitude = mvmt.pickup.longitude;
      pu.pickup_location = mvmt.pickup.location?.location_name;
      pu.delivery_location = mvmt.delivery.location?.location_name;
      pu.company_location_id = mvmt.pickup.location?.company_location_id;
      pu.location_name = mvmt.pickup.location?.location_name;
      pu.address1 = mvmt.pickup.location?.address1;
      pu.city = mvmt.pickup.location?.city;
      pu.state = mvmt.pickup.location?.state;
      pu.zip = mvmt.pickup.location?.zip;
      pu.style = pickup_style;
      pu.company_location_id1 = mvmt.delivery.location?.company_location_id;
      pu.location_name1 = mvmt.delivery.location?.location_name;
      pu.address11 = mvmt.delivery.location?.address1;
      pu.city1 = mvmt.delivery.location?.city;
      pu.state1 = mvmt.delivery.location?.state;
      pu.zip1 = mvmt.delivery.location?.zip;
      pu.trailerDetails = mvmt?.pickup?.trailerDetails;

      // let prev_move_id=driver_movements?.find(x=>x.dlv_lm_stop_id=== mvmt.pickup.lm_stop_id )
      // // pu.associated_move_ids=[ mvmt.load_id,prev_move_id?.load_id]

      movements.push(pu);

      let drive_to_del = {};
      drive_to_del.id = mvmt.load_id;
      drive_to_del.orderId = mvmt.order_id;
      drive_to_del.orderId2 = mvmt.order_id2;
      drive_to_del.type = mvmt.movement_type;
      drive_to_del.stop_pickup_type = mvmt.pickup.stop_type;
      drive_to_del.trailer_id = ["SS", "ES", "BT"].includes(mvmt.movement_type)
        ? null
        :  mvmt.trailer_id
          ?  mvmt.trailer_id
          : "";
      drive_to_del.orig_trailer_id =  mvmt.trailer_id;
      drive_to_del.move_id = mvmt.load_id;
      drive_to_del.load_id = mvmt.load_id;
      drive_to_del.distance = mvmt.distance;
      drive_to_del.movement_status = mvmt.status;
      drive_to_del.trailerData = mvmt.trailer;
      drive_to_del.movementData = mvmt;
      drive_to_del.movementData.driver = driverData;
      drive_to_del.stopData = null;
      drive_to_del.terminal_id = mvmt.terminal_id;
      drive_to_del.startTime = pu_depart_utc;
      drive_to_del.endTime = del_arrive_utc;
      drive_to_del.eta_arrival_utc = mvmt.pickup.eta_arrival_utc;
      drive_to_del.driverId = mvmt.driver_id;
      drive_to_del.pickup_timezone = mvmt.pickup.pickup_timezone;
      drive_to_del.planned_arrive_utc = pu_arrive_utc;
      drive_to_del.planned_depart_utc = pu_depart_utc;
      drive_to_del.actual_arrival_utc = mvmt.pickup.actual_arrival_utc;
      drive_to_del.actual_departure_utc = mvmt.pickup.actual_departure_utc;
      drive_to_del.eta_arrival_utc = mvmt.pickup.eta_arrival_utc;
      drive_to_del.eta_departure_utc = mvmt.pickup.eta_departure_utc;
      drive_to_del.latitude = mvmt.pickup?.latitude;
      drive_to_del.longitude = mvmt.pickup.longitude;
      drive_to_del.pickup_location = mvmt.pickup.location?.location_name;
      drive_to_del.delivery_location = mvmt.delivery.location?.location_name;
      drive_to_del.move_id = mvmt.load_id;
      drive_to_del.brokerage = mvmt.brokerage;
      drive_to_del.company_location_id =
        mvmt.pickup.location?.company_location_id;
      drive_to_del.location_name = mvmt.pickup.location?.location_name;
      drive_to_del.address1 = mvmt.pickup.location?.address1;
      drive_to_del.city = mvmt.pickup.location?.city;
      drive_to_del.state = mvmt.pickup.location?.state;
      drive_to_del.zip = mvmt.pickup.location?.zip;
      drive_to_del.company_location_id1 =
        mvmt.delivery.location?.company_location_id;
      drive_to_del.location_name1 = mvmt.delivery.location?.location_name;
      drive_to_del.address11 = mvmt.delivery.location?.address1;
      drive_to_del.city1 = mvmt.delivery.location?.city;
      drive_to_del.state1 = mvmt.delivery.location?.state;
      drive_to_del.zip1 = mvmt.delivery.location?.zip;

      drive_to_del.style = {};

      movements.push(drive_to_del);

      let delivery_style = {};
      // if (mvmt.delivery.eta_arrival_utc > mvmt.delivery.sched_arrive_late_utc) {
      //   delivery_style = {
      //     background: "#b53329",
      //     color: "white",
      //   };
      // }

      // else if(mvmt.delivery.eta_arrival_utc===0||mvmt.delivery.eta_arrival_utc===null||mvmt.delivery.eta_arrival_utc===undefined){
      //   delivery_style = {
      //     background: "#5eab5e",
      //     color: "white",
      //   };
      // }

      // else if (
      //   mvmt.delivery.eta_arrival_utc > del_arrive_utc &&
      //   mvmt.delivery.eta_arrival_utc <= mvmt.delivery.sched_arrive_late_utc
      // ) {
      //   delivery_style = { background: "#c9af31", color: "white" };
      // } else if (mvmt.delivery.eta_arrival_utc <= del_arrive_utc) {
      //   delivery_style = { background: "#5eab5e", color: "white" };
      // }

       if (mvmt.delivery.eta_arrival_utc > mvmt.delivery.planned_arrive_utc) {
        delivery_style = {
          background: "#c9af31", // Yellow
          color: "white",
        }
      }else {
        delivery_style = {
          background: "#5eab5e", // Green
          color: "white",
        };
      }

      let del = {};
      del.id = mvmt.delivery.id;
      del.orderId = mvmt.order_id;
      del.orderId2 = mvmt.order_id2;
      del.move_id = mvmt.load_id;
      del.load_id = mvmt.load_id;
      del.type =
        mvmt.delivery.stop_type == "split delivery"
          ? "SD"
          : mvmt.delivery.stop_type == "split pickup"
            ? "SP"
            : mvmt.delivery.stop_type;
      del.stop_delivery_type = mvmt.delivery.stop_type;
      del.movement_status = mvmt.status;
      del.trailer_id = ["SS", "ES", "BT"].includes(mvmt.delivery.stop_type)
        ? null
        :  mvmt.trailer_id
          ?  mvmt.trailer_id
          : "";
      del.orig_trailer_id =  mvmt.trailer_id;
      del.mvmt_load_id = mvmt.load_id;
      del.movementData = mvmt;
      del.stopData = {
        ...mvmt.delivery,
      };
      del.trailerData = mvmt.trailer;
      del.terminal_id = mvmt.terminal_id;
      del.startTime = del_arrive_utc;
      del.endTime = del_depart_utc;
      del.delivery_planned_arrive = mvmt.delivery.planned_arrive_utc;
      del.delivery_planned_depart = mvmt.delivery.planned_depart_utc;
      del.delivery_sched_arrive_early_utc =
        mvmt.delivery.sched_arrive_early_utc;
      del.delivery_sched_arrive_late_utc = mvmt.delivery.sched_arrive_late_utc;
      del.delivery_eta_utc = mvmt.delivery.eta_arrival_utc;
      del.delivery_actual_arrive = mvmt.delivery.actual_arrival_utc;
      del.delivery_actual_depart = mvmt.delivery.actual_departure_utc;
      del.delivery_eta_arrive = mvmt.delivery.eta_arrival_utc;
      del.delivery_eta_depart = mvmt.delivery.eta_departure_utc;
      del.stop_delivery_type = mvmt.delivery.stop_type;
      del.delivery_timezone = mvmt.delivery.delivery_timezone;
      del.driverId = mvmt.driver_id;
      del.latitude = mvmt.delivery?.latitude;
      del.longitude = mvmt.delivery?.longitude;
      del.pickup_location = mvmt.pickup.location?.location_name;
      del.delivery_location = mvmt.delivery.location?.location_name;
      del.style = delivery_style;
      del.company_location_id1 = mvmt.delivery.location?.company_location_id;
      del.location_name1 = mvmt.delivery.location?.location_name;
      del.address11 = mvmt.delivery.location?.address1;
      del.city1 = mvmt.delivery.location?.city;
      del.state1 = mvmt.delivery.location?.state;
      del.zip1 = mvmt.delivery.location?.zip;
      del.eta_arrival_utc = mvmt.pickup.eta_arrival_utc;
      del.trailerDetails = mvmt?.delivery?.trailerDetails;

      movements.push(del);
    }

    return movements;
  } catch (err) {
    return [];
  }
};

export const getBoardByTrailerFormatted = async (
  movementData,
  driverData,
  trailers,
  plannedMode = true
) => {
  const planningBoard = new PlanningBoard();
  // let planningObj = [];
  let planners = [];
  try {
    // let planningObjByTrailer = planningBoardData.data.reduce((r, a) => {
    //   r[a.movement.trailer_id] = [...(r[a.movement.trailer_id] || []), a];
    //   return r;
    // }, {});
    // planningObj = planningBoardData.data.map((it) =>
    //   planningBoard.parseApiPlanningBoardObject(it)
    // );
    // if (planningObj === null) return planners;


    for (let loop = 0; loop < trailers.length; loop++) {
      const movementdata_arr = [];
      const block = {};
      const trailers_data = trailers[loop];
      // const trailers_movements =
      movementData
        ?.data
        ?.filter(
          (mvmt) => {
            // mvmt?.trailer_id === trailers_data.Trailer_ID

            // mvmt
            // ?.trailer_id === trailers_data
            // ?.trailer_id
            if (mvmt && mvmt.trailer_id) {
              if (mvmt.trailer_id && trailers_data.Trailer_ID) {
                if (mvmt.trailer_id === trailers_data.Trailer_ID) {

                  movementdata_arr.push(mvmt)
                }
              }
            }
          }

        );



      const trailers_movements = movementdata_arr;

      block.trailer_id = trailers_data.Trailer_ID;
      block.type = trailers_data.Equip_Type;
      block.terminal = trailers_data.Terminal;
      block.terminal_city = trailers_data.City;
      // block.license_plate = trailers_data?.license_plate;
      block.year = trailers_data.Year;
      block.state = trailers_data.State;
      block.make = trailers_data.Make;
      block.model = trailers_data.Model;
      block.license = trailers_data.License_Plate;
      block.stat = trailers_data.Status;
      // block.shipper_pool = trailers_data.code;
      block.dedicated = trailers_data.code;
      block.movement_status = trailers_data.Status;
      block.tare_weight = trailers_data.Tare_Weight;
      block.pm_due_date_utc = trailers_data.PM_Due_Date;
      block.Last_Wash_WO = trailers_data['Last_Wash_W/O'];
      block.Loads_to_Next_Wash = trailers_data.Loads_to_Next_Wash;
      block.Days_to_Next_Wash = trailers_data.Days_to_Next_Wash
      block.Last_Order = trailers_data.Last_Order;
      block.Last_Commodity = trailers_data.Last_Commodity;
      block.Last_Product = trailers_data.Last_Product;
      block.Last_Washed = trailers_data.Last_Washed;
      block.commodity_group = trailers_data.Commodity_Group;
      block.Loads_Since_Last_Wash = trailers_data.Loads_Since_Last_Wash;
      block.Days_Since_Last_Wash = trailers_data.Days_Since_Last_Wash;
      block.Length = trailers_data.Length;
      block.Width = trailers_data.Width;
      block.Height = trailers_data.Height;
      block.Volume = trailers_data.Volume;

      try {
        block.movements = getPlanningBoardDriverMovements({
          driver_movements: trailers_movements,
          plannedMode: plannedMode,

        });
        block.movements.sort((a, b) => {
          return a.startTime - b.startTime;
        });

        // trim mvmt/stop blocks
        block.movements = block.movements
          .map((mvmt, idx) => {
            if (idx >= block.movements.length - 1) return mvmt;
            let next = idx;
            while (
              next < block.movements.length - 1 &&
              block.movements[next].startTime ==
              block.movements[idx].startTime
            )
              next += 1;
            return {
              ...mvmt,
              endTime: Math.min(
                mvmt.endTime,
                block.movements[next]?.startTime
              ),
            };
          })
          .filter((mvmt) => mvmt.startTime <= mvmt.endTime);

        // dedupe
        block.movements = [
          ...block.movements.filter((it) => it?.stopData == null),
          ...[
            ...new Set(
              block.movements
                .filter((it) => it?.stopData != null)
                .map((it) => it?.stopData?.lm_stop_id)
            ),
          ].map((stop_id) => {
            let stops = block.movements.filter(
              (it) => it?.stopData?.lm_stop_id == stop_id
            );
            if (stops.length == 1) return stops[0];
            return stops.filter(
              (stop) => stop.movementData.pickup.lm_stop_id == stop_id
            )[0];
          }),
        ].filter((m) => m);

        block.movements.sort((a, b) => {
          return a.startTime - b.startTime;
        });

        // Add linked mvmts to stops
        block.movements = block.movements.map((mvmt, idx) => {
          if (mvmt.stopData) {
            let linkedMvmts = [
              idx > 0
                ? {
                  move_id: block.movements[idx - 1]?.move_id,
                  type: block.movements[idx - 1]?.movementData
                    ?.movement_type,
                }
                : null,
              idx < block.movements.length - 1
                ? {
                  move_id: block.movements[idx + 1]?.move_id,
                  type: block.movements[idx + 1]?.movementData
                    ?.movement_type,
                }
                : null,
            ].filter((i) => i);

            return {
              ...mvmt,
              stopData: {
                ...mvmt.stopData,
                linkedMvmts,
              },
            };
          }
          return mvmt;
        });

        let orders = [];
        let drivers = [];
        let final_shifts = [];

        for (let i = 0; i < block.movements.length; i++) {
          let it = block.movements[i];
          if (
            !(
              [null, "", undefined].includes(
                ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                  ["", null, undefined].includes(it.orderId2)
                  ? it.orderId
                  : it.orderId2
              ) || ["SS", "ES", "BT", "E", "HT", "DT"].includes(it.type)
            )
          ) {
            let order = {};
            let movementData = it.movementData;

            order.orderId =
              ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                ["", null, undefined].includes(it.orderId2)
                ? it.orderId
                : it.orderId2;
            order.mvmtType = it.type;
            order.terminal_id = it.terminal_id;
            order.orderStartTime = it.startTime;
            order.orderEndTime = it.endTime;
            order.commodity = it.commodity;
            order.status = it.status;
            order.bol_received = it.bol;
            order.ordered_wt = it.weight;
            order.pickup_location = movementData.pickup.location;
            order.delivery_location = movementData.delivery.location;
            order.terminalId = it.terminal_id;
            order.order_status = it.status;
            order.location_name = movementData.pickup.location.location_name;
            order.address1 = movementData.pickup.location.address1;
            order.city = movementData.pickup.location.city;
            order.state = movementData.pickup.location.state;
            order.zip = movementData.pickup.location.zip;
            order.move_id = movementData.load_id;
            order.company_location_id1 =
              movementData.delivery.location.company_location_id;
            order.location_name1 = movementData.delivery.location.location_name;
            order.address11 = movementData.delivery.location.address1;
            order.city1 = movementData.delivery.location.city;
            order.state1 = movementData.delivery.location.state;
            order.zip1 = movementData.delivery.location.zip;
            order.delivery_driver_load_unload =
              movementData.delivery.driver_load_unload;
            order.pickup_driver_load_unload =
              movementData.pickup.driver_load_unload;
            order.pickup_appt_required = movementData.pickup.appt_required;
            order.picup_confirmed = movementData.pickup.confirmed;
            order.pickup_sched_arrive_early_utc =
              movementData.pickup.sched_arrive_early_utc;
            order.pickup_sched_arrive_late_utc =
              movementData.pickup.sched_arrive_late_utc;
            order.delivery_sched_arrive_early_utc =
              movementData.delivery.sched_arrive_early_utc;
            order.delivery_sched_arrive_late_utc =
              movementData.delivery.sched_arrive_late_utc;
            order.distance = movementData.distance;
            order.pickup_timezone = movementData.pickup.pickup_timezone;
            order.delivery_timezone = movementData.delivery.delivery_timezone;
            order.commoditygroupid = it.order_commodity?.commoditygroupid
              ? it.order_commodity?.commoditygroupid
              : "";
            order.commoditygroup_description = it.order_commodity?.description
              ? it.order_commodity?.description
              : "";
            order.planId=movementData.pickup.plan_id

            // if (it.movementData.xmit_accepted == "Y")
            //   order.style = { background: "#5eab5e", color: "white" };
            // else if ([0, null, undefined, ""].includes(it.movementData.xmitted2driver) && [0, null, undefined, "", "N"].includes(it.movementData.xmit_accepted))
            //   order.style = { background: "d17c21", color: "white" };

            if ([0, null, undefined, ""].includes(it.movementData.xmitted2driver_utc))
              order.style = { backgroundColor: "white", color: "grey" };
            else {
              if (
                [0, null, undefined, "",'N'].includes(it.movementData.xmit_accepted)
              ){
                order.style = { backgroundColor: "orange", color: "white" };
              }
              else {
                console.log({message: 'color', order});
                order.style = { backgroundColor: "green", color: "white" };
              }
            }

            if(!["", null, undefined].includes(order.orderId))
              orders.push(order);
          }


          if (it.driverId !== null) {
            const driData = driverData.find(d=>d.driver_id == it.driverId);

            if (driData) {
              let driver = {
                driverId: it.driverId,
                driver_full_name: driData.driver_full_name,
                samsara_id: driData.samsara_id,
                driver_type_class: driData.driver_type_class,
                hire_date: driData.hire_date,
                terminal: driData.terminal_id,
                terminal_city: driData.terminal.terminal_fullName,
                schedule: driData.schedule,
                hosclock: driData.hosclock ?? {},
                driverStartTime: it.startTime,
                driverEndTime: it.endTime
              };
              if (!["SS", "ES"].includes(it.type))
                drivers.push(driver);
            }
          }

          if (["SS", "ES"].includes(it.type))
            final_shifts.push({
              shiftType: it.type === "SS" ? "start" : "end",
              shiftStartTime: it.startTime,
              shiftEndTime: it.endTime,
              move_id: it.move_id,
              movementData: it.movementData,
            });
        }

        const final_orders = [];
        const final_drivers = [];

        let idxs = [];
        orders.sort((a, b) => a.startTime - b.startTime);
        orders.forEach((it, idx) => {
          if (idxs.includes(idx)) return;
          let order = { ...it };
          let obstacle = false;

          /** Confusion */
          orders.slice(idx + 1).forEach((it2, idx2) => {
            if (
              obstacle ||
              (it2.orderId != order.orderId &&
                it2.orderStartTime != order.orderStartTime) ||
              (["SS", "ES", "BT", "E", "HT", "DT"].includes(it2.mvmtType) &&
                Math.abs(it2.orderEndTime - it2.orderStartTime) > 0) ||
              order.orderEndTime < it2.orderStartTime
            ) {
              obstacle = true;
              return;
            } else {
              idxs.push(idx2 + idx + 1);
              order.orderId = it2.orderId;
              order.orderEndTime = it2.orderEndTime;
            }
          });
          final_orders.push(order);
          idxs.push(idx);
        });

        idxs = [];

        drivers.sort((a, b) => a.driverStartTime - b.driverStartTime);
        drivers.forEach((it, idx) => {
          if (idxs.includes(idx)) return;
          let driver = {
            ...it,
            mvmt_load_ids: [],
            all_orders: [],
          };
          let obstacle = false;
          drivers.slice(idx).forEach((it2, idx2) => {

            if (
              obstacle ||
              (it2.driverId != it.driverId  &&
                it2.driverEndTime - it2.driverStartTime > 0) ||
              driver.driverEndTime < it2.driverStartTime
            ) {
              obstacle = true;
              return;
            } else {
              idxs.push(idx2 + idx);
              driver.mvmt_load_ids.push(it2.mvmt_load_ids);
              driver.all_orders.push(it2.order_id);
              driver.driverEndTime = it2.driverEndTime;
            }
          });
          driver.mvmt_load_ids = [...new Set(driver.mvmt_load_ids)];
          driver.all_orders = [...new Set(driver.all_orders)];
          final_drivers.push(driver);
          idxs.push(idx);
        });

        block.orders = final_orders;
        block.drivers = final_drivers;
        if(final_drivers.length==0 && final_orders.length>0){
        let finalDrivers=final_orders?.map((el)=>{
          return{
            driverStartTime:el.orderStartTime,
            driverEndTime:el.orderEndTime
            }
          })
          block.drivers = finalDrivers;
        }
        block.shifts = final_shifts;

        block.movements = block.movements.filter(
          (mvmt) => !["SS", "ES"].includes(mvmt.type)
        );

        let mvmt_map_vals = block.movements.map((v) => [
          JSON.stringify([v.startTime, v.endTime]),
          v,
        ]);

        [
          ...mvmt_map_vals
            .filter((it) => it[1]?.stopData != null)
            .map((it) => it[1]?.stopData?.lm_stop_id),
        ].forEach((key) => {
          mvmt_map_vals = mvmt_map_vals.map((mvmt) => {
            if (mvmt[1]?.stopData?.lm_stop_id == key) {
              let linkedMvmts = [
                ...new Set(
                  mvmt_map_vals
                    .filter((it) => it[1]?.stopData?.lm_stop_id == key)
                    .map((it) => ({
                      move_id: it[1]?.move_id,
                      type: it[1].movementData.movement_type,
                      status:it[1].movementData?.status,
                    }))
                ),
              ];
              return [
                mvmt[0],
                {
                  ...mvmt[1],
                  stopData: {
                    ...mvmt[1].stopData,
                    linkedMvmts,
                  },
                },
              ];
            }
            return mvmt;
          });
        });

        block.movements = [...new Map(mvmt_map_vals).values()];

        block.movements.sort((a, b) => {
          return a.endTime - b.endTime;
        });

        block.isloading = false;
      } catch (err) {
        console.trace(err);
      }
      planners.push(block);
    }
    return Promise.resolve(planners);
  } catch (err) {
    return Promise.reject(
      "There is a problem on retrieving planning board data. Please try again!"
    );
  }
};

export const getBoardByTractorFormatted = async (
  movementData,
  tractorData,
  driverData,
  plannedMode = true

) => {


  let planners = [];


  try {
    planners = await Promise.all(
      tractorData.map(async (tractor_data) => {
        const block = {};
        const driver_movements = movementData?.data?.filter(
          (mvmt) => mvmt?.tractor?.tractor_id === tractor_data?.tractor_id
        );

        block.tractor_id = tractor_data.tractor_id;
        block.blower = tractor_data.blower;

        block.company_id = tractor_data.company_id;

        block.description = tractor_data.description;
        block.double_certification = tractor_data.double_certification;

        block.equipment_id = tractor_data.equipment_id;
        block.equipment_type = tractor_data.equipment_type;

        block.first_meter_accumulated_usage = tractor_data.first_meter_accumulated_usage;
        block.first_meter_est_life = tractor_data.first_meter_est_life;
        block.first_meter_monthly_average = tractor_data.first_meter_monthly_average;
        block.first_meter_original_reading = tractor_data.first_meter_original_reading;
        block.first_meter_original_reading_date = tractor_data.first_meter_original_reading_date;
        block.first_meter_proj_life = tractor_data.first_meter_proj_life;
        block.first_meter_reading = tractor_data.first_meter_reading;
        block.first_meter_reading_date = tractor_data.first_meter_reading_date;
        block.first_meter_type = tractor_data.first_meter_type;
        block.first_meter_var_life = tractor_data.first_meter_var_life;
        block.first_meter_weighted_daily_usage = tractor_data.first_meter_weighted_daily_usage;
        block.fleetcode = tractor_data.fleetcode;
        block.governing_speed_limit = tractor_data.governing_speed_limit;

        block.gross_weight = tractor_data.gross_weight;
        block.id = tractor_data.id;
        block.is_active = tractor_data.is_active;

        block.last_modified_by = tractor_data.last_modified_by;
        block.last_modified_date = tractor_data.last_modified_date;
        block.latitude = tractor_data.latitude;
        block.license_country = tractor_data.license_country;
        block.license_plate = tractor_data.license_plate;
        block.license_renewal_date = tractor_data.license_renewal_date;

        block.license_state = tractor_data.license_state;
        block.longitude = tractor_data.longitude;
        block.make = tractor_data.make;
        block.model = tractor_data.model;
        block.model_year = tractor_data.model_year;
        block.overweight_permit = tractor_data.overweight_permit;
        block.pm_due_date_utc = tractor_data.pm_due_date_utc;
        block.region = tractor_data.region;
        block.second_meter_accumulated_usage = tractor_data.second_meter_accumulated_usage;
        block.second_meter_est_life = tractor_data.second_meter_est_life;
        block.second_meter_monthly_average = tractor_data.second_meter_monthly_average;

        block.second_meter_original_reading = tractor_data.second_meter_original_reading;
        block.second_meter_reading_date = tractor_data.second_meter_reading_date;
        block.second_meter_reading = tractor_data.second_meter_reading;
        block.second_meter_reading_date = tractor_data.second_meter_reading_date;
        block.second_meter_type = tractor_data.second_meter_type;
        block.second_meter_weighted_daily_usage = tractor_data.second_meter_weighted_daily_usage;
        block.sleeper_daycab = tractor_data.sleeper_daycab;
        block.source_system = tractor_data.source_system;
        block.status = tractor_data.status;
        block.tare_weight = tractor_data.tare_weight;
        block.terminal = tractor_data.terminal;


        block.terminal_full_name = tractor_data.terminal_full_name;
        block.terminal_id = tractor_data.terminal_id;
        block.terminal_name = tractor_data.terminal_name;
        block.tran_description = tractor_data.tran_description;
        block.transmission_auto_manual = tractor_data.transmission_auto_manual;
        block.unitcode = tractor_data.unitcode;
        block.vin = tractor_data.vin;
        block.wetkit = tractor_data.wetkit;
        block.wheelbase = tractor_data.wheelbase;

        try {

          block.movements = getPlanningBoardDriverMovements({
            driver_movements: driver_movements,
            plannedMode: plannedMode,
          });

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

          // trim mvmt/stop blocks
          block.movements = block.movements
            .map((mvmt, idx) => {
              if (idx >= block.movements.length - 1) return mvmt;
              let next = idx;
              while (
                next < block.movements.length - 1 &&
                block.movements[next].startTime ==
                block.movements[idx].startTime
              )
                next += 1;
              return {
                ...mvmt,
                endTime: Math.min(
                  mvmt.endTime,
                  block.movements[next]?.startTime
                ),
              };
            })
            .filter((mvmt) => mvmt.startTime <= mvmt.endTime);


          // dedupe
          block.movements = [
            ...block.movements.filter((it) => it?.stopData == null),
            ...[
              ...new Set(
                block.movements
                  .filter((it) => it?.stopData != null)
                  .map((it) => it?.stopData?.lm_stop_id)
              ),
            ].map((stop_id) => {
              let stops = block.movements.filter(
                (it) => it?.stopData?.lm_stop_id == stop_id
              );
              if (stops.length == 1) return stops[0];
              return stops.filter(
                (stop) => stop.movementData.pickup.lm_stop_id == stop_id
              )[0];
            }),
          ].filter((m) => m);

          block.movements.sort((a, b) => {
            return a.startTime - b.startTime;
          });

          // Add linked mvmts to stops
          block.movements = block.movements.map((mvmt, idx) => {
            if (mvmt.stopData) {
              let linkedMvmts = [
                idx > 0
                  ? {
                    move_id: block.movements[idx - 1]?.move_id,
                    type: block.movements[idx - 1]?.movementData
                      ?.movement_type,
                        status:block.movements[idx-1].movementData?.status
                  }
                  : null,
                idx < block.movements.length - 1
                  ? {
                    move_id: block.movements[idx + 1]?.move_id,
                    type: block.movements[idx + 1]?.movementData
                      ?.movement_type,
                        status:block.movements[idx+1].movementData?.status
                  }
                  : null,
              ].filter((i) => i);

              return {
                ...mvmt,
                stopData: {
                  ...mvmt.stopData,
                  linkedMvmts,
                },
              };
            }
            return mvmt;
          });

          let orders = [];
          let drivers = [];
          let final_shifts = [];

          for (let i = 0; i < block.movements.length; i++) {
            let it = block.movements[i];
            if (
              !(
                [null, "", undefined].includes(
                  ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                    ["", null, undefined].includes(it.orderId2)
                    ? it.orderId
                    : it.orderId2
                ) || ["SS", "ES", "BT", "E", "HT", "DT"].includes(it.type)
              )
            ) {
              let order = {};
              let movementData = it.movementData;

              order.orderId =
                ["PU", "DEL", "SP", "SD", "L"].includes(it.type) &&
                  ["", null, undefined].includes(it.orderId2)
                  ? it.orderId
                  : it.orderId2;
              order.mvmtType = it.type;
              order.terminal_id = it.terminal_id;
              order.orderStartTime = it.startTime;
              order.orderEndTime = it.endTime;
              order.commodity = it.commodity;
              order.status = it.status;
              order.bol_received = it.bol;
              order.ordered_wt = it.weight;
              order.pickup_location = movementData.pickup.location;
              order.delivery_location = movementData.delivery.location;
              order.terminalId = movementData.driver.terminal_id;
              order.order_status = it.status;
              order.location_name = movementData.pickup.location.location_name;
              order.address1 = movementData.pickup.location.address1;
              order.city = movementData.pickup.location.city;
              order.state = movementData.pickup.location.state;
              order.zip = movementData.pickup.location.zip;
              order.move_id = movementData.load_id;
              order.company_location_id1 =
                movementData.delivery.location.company_location_id;
              order.location_name1 =
                movementData.delivery.location.location_name;
              order.address11 = movementData.delivery.location.address1;
              order.city1 = movementData.delivery.location.city;
              order.plan_id = movementData.delivery.plan_id;
              order.state1 = movementData.delivery.location.state;
              order.zip1 = movementData.delivery.location.zip;
              order.delivery_driver_load_unload =
                movementData.delivery.driver_load_unload;
              order.pickup_driver_load_unload =
                movementData.pickup.driver_load_unload;
              order.pickup_appt_required = movementData.pickup.appt_required;
              order.picup_confirmed = movementData.pickup.confirmed;
              order.pickup_sched_arrive_early_utc =
                movementData.pickup.sched_arrive_early_utc;
              order.pickup_sched_arrive_late_utc =
                movementData.pickup.sched_arrive_late_utc;
              order.delivery_sched_arrive_early_utc =
                movementData.delivery.sched_arrive_early_utc;
              order.delivery_sched_arrive_late_utc =
                movementData.delivery.sched_arrive_late_utc;
              order.distance = movementData.distance;
              order.pickup_timezone = movementData.pickup.pickup_timezone;
              order.delivery_timezone = movementData.delivery.delivery_timezone;
              order.commoditygroupid = it.order_commodity?.commoditygroupid
                ? it.order_commodity?.commoditygroupid
                : "";
              order.commoditygroup_description = it.order_commodity?.description
                ? it.order_commodity?.description
                : "";
              order.actual_arrival_utc = movementData.pickup.actual_arrival_utc;
              order.eta_arrival_utc = movementData.pickup.eta_arrival_utc;
              order.eta_departure_utc = movementData.pickup.eta_departure_utc;
              order.actual_departure_utc =
                movementData.pickup.actual_departure_utc;
              order.actual_arrival_utc1 =
                movementData.delivery.actual_arrival_utc;
              order.actual_departure_utc1 =
                movementData.delivery.actual_departure_utc;
              order.eta_arrival_utc1 =
                movementData.delivery.eta_arrival_utc;
              order.eta_departure_utc1 =
                movementData.delivery.eta_departure_utc;

              order.style = {};

              if ([0, null, undefined, ""].includes(movementData.xmitted2driver_utc))
                order.style = { backgroundColor: "white", color: "grey" };
              else {
                if (
                  [0, null, undefined, ""].includes(movementData.xmit_accepted)
                )
                  order.style = { backgroundColor: "orange", color: "white" };
                else
                  order.style = { backgroundColor: "green", color: "white" };
              }

              orders.push(order);
            }
            if (it.driverId !== null) {
              const driData = driverData.find(d=>d.driver_id == it.driverId);

              if (driData) {
                let driver = {
                  driverId: it.driverId,
                  driver_full_name: it.movementData.driver.driver_full_name,
                  samsara_id: it.movementData.driver.samsara_id,
                  driver_type_class: it.movementData.driver.driver_type_class,
                  hire_date: it.movementData.driver.hire_date,
                  terminal: it.movementData.driver.terminal_id,
                  terminal_city: driData.terminal.name,
                  schedule: driData.schedule,
                  hosclock: driData.hosclock ?? {},
                  driverStartTime: it.startTime,
                  driverEndTime: it.endTime
                };
                // if (!["SS", "ES"].includes(it.type))
                drivers.push(driver);
              }
            }

            if (["SS", "ES"].includes(it.type))
              final_shifts.push({
                shiftType: it.type === "SS" ? "start" : "end",
                shiftStartTime: it.startTime,
                shiftEndTime: it.endTime,
                move_id: it.move_id,
                movementData: it.movementData,
              });
          }

          let final_orders = [];
          let final_drivers = [];

          let idxs = [];
          orders.sort((a, b) => a.startTime - b.startTime);
          orders.forEach((it, idx) => {
            if (idxs.includes(idx)) return;
            let order = { ...it };
            let obstacle = false;

            orders.slice(idx + 1).forEach((it2, idx2) => {
              if (
                obstacle ||
                ((it2.orderId != order.orderId &&
                  it2.orderStartTime != order.orderStartTime) ||
                  (["SS", "ES", "BT", "E", "HT", "DT"].includes(it2.mvmtType) &&
                    Math.abs(it2.orderEndTime - it2.orderStartTime) > 0)) &&
                !["DEL", "SD"].includes(it2.mvmtType) ||
                order.orderEndTime < it2.orderStartTime
              ) {
                obstacle = true;
                return;
              } else {
                idxs.push(idx2 + idx + 1);
                if ([null, undefined, ""].includes(order.orderId)) order.orderId = it2.orderId;
                order.orderEndTime = it2.orderEndTime;
              }
            });

            final_orders.push(order);
            idxs.push(idx);
          });

          idxs = [];

          drivers.sort((a, b) => a.driverStartTime - b.driverStartTime);
          drivers.forEach((it, idx) => {
            if (idxs.includes(idx)) return;
            let driver = {
              ...it,
              mvmt_load_ids: [],
              all_orders: [],
            };
            let obstacle = false;
            drivers.slice(idx).forEach((it2, idx2) => {

              if (
                obstacle ||
                (it2.driverId != it.driverId  &&
                  it2.driverEndTime - it2.driverStartTime > 0) ||
                driver.driverEndTime < it2.driverStartTime
              ) {
                obstacle = true;
                return;
              } else {
                idxs.push(idx2 + idx);
                driver.mvmt_load_ids.push(it2.mvmt_load_ids);
                driver.all_orders.push(it2.order_id);
                driver.driverEndTime = it2.driverEndTime;
              }
            });
            driver.mvmt_load_ids = [...new Set(driver.mvmt_load_ids)];
            driver.all_orders = [...new Set(driver.all_orders)];
            final_drivers.push(driver);
            idxs.push(idx);
          });

          block.orders = final_orders;
          block.drivers = final_drivers;
          if(final_drivers.length==0 && final_orders.length>0){
          let finalDrivers=final_orders?.map((el)=>{
            return{
              driverStartTime:el.orderStartTime,
              driverEndTime:el.orderEndTime
              }
            })
            block.drivers = finalDrivers;
          }

          let newEmptyMoveByPlanner=[];
          block.movements?.forEach((movement)=>{
            if(movement?.type=='E' && movement?.movementData?.is_planner_inserted){
              newEmptyMoveByPlanner.push(movement)
            }
          })
          block.orders = final_orders;

          block.shifts = final_shifts;
          block.newEmptyMoveByPlanner=newEmptyMoveByPlanner;
          block.movements = block.movements.filter(
            (mvmt) => !["SS", "ES"].includes(mvmt.type)
          );
          let driverOffduty=[];
          let endShits=block.movements?.filter((mov) => mov.movementData.delivery.stop_type === "ES" && mov.stopData === null).sort((a, b) => a.planned_arrive_utc - b.planned_arrive_utc);
          endShits.length>0 && endShits?.forEach((el)=>{
            let obj={
              logStartTime:el.movementData.delivery.planned_depart_utc,
              logEndTime:el.movementData.delivery.planned_depart_utc + 10 * 60 * 60 * 1000
            }
            driverOffduty.push(obj)
          })
          block.schedule.offduty=driverOffduty
          block.isloading = false;
        } catch (err) {
          console.log(err);
        }
        return block;
      })
    );


  } catch (err) {
    console.log({err});
    return [];
  }
  return planners;
};

export const getAlerts = async () => {
  try {
    const url = BaseConfig.ApiEndPoint + "/alerts";
    const alertsResponse = await axios.get(url);
    return alertsResponse;
  } catch (err) {
    throw err;
  }
};

export const dismissAlert = async (alert_id) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/dismissalert";
    const requestBody = {
      alert_id: alert_id,
    };
    const alertsResponse = await axios.post(url, requestBody);
    return alertsResponse;
  } catch (err) {
    throw err;
  }
};

export const getOrderById = async (id) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/orders/" + id;
    const orderResponse = await axios.get(url);
    return orderResponse;
  } catch (error) {
    throw error;
  }
};

export const createemptymove = async (obj) => {
  let response = {};
  try {
    const url = BaseConfig.ApiEndPoint + "/createemptymove";
    response = await axios.post(url, obj);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatemovementbyloadid = async (move_id, obj) => {
  try {
    let config = {
      method: "put",
      url: BaseConfig.ApiEndPoint + "/updatemovementbyloadid/" + move_id,
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const pcmilerRouteReports = async (obj) => {
  try {
    var config = {
      method: "post",
      url: `${Config[Config.environment].pcMilerApiUrl}/Service.svc/route/routeReports?dataVersion=Current`,
      headers: {
        Authorization: `${Config[Config.environment].pcmilerkey}`,
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createmovement = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createmovement",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createmultiplemovements = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createmultiplemovements",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatemultiplemovements = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/updatemultiplemovements",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatestopbylmstopid = async (obj) => {
  try {
    var config = {
      method: "put",
      url: BaseConfig.ApiEndPoint + "/updatestopbylmstopid",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatemultiplestops = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/updatemultiplestops",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createmultiplestops = async (obj) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/createmultiplestops",
      headers: {
        "Content-Type": "application/json",
      },
      data: obj,
    };

    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getmovementstopbyterminalid = async (payload,timeZone,searchData,selectedTab,isTrailerView) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/getmovementstopbyterminalid",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        date: payload.date,
        terminals: payload.terminals,
        timeZone,
        searchValue:searchData,
        selectedTab:selectedTab=="left" ? "planned" : "actual",
        isTrailerView: isTrailerView == 'trailer'
      },
    };

    let response = await axios(config);
    const mvmvtStopApiResponse = atob(response.data);

    // Convert binary string to character-number array
    const charData = mvmvtStopApiResponse.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let mvmvtStopApiDataUnCompressed = fflate.unzlibSync(binData);
    let mvmvtStopApiDataUnCompressedString = fflate.strFromU8(
      mvmvtStopApiDataUnCompressed
    );
    const mvmvtStopApiDecompressedObject = JSON.parse(
      mvmvtStopApiDataUnCompressedString
    );

    return Promise.resolve(mvmvtStopApiDecompressedObject);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeMovementByloadId = async (payLoad) => {
  let orderobject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/removemovementbyloadid";
    const deleteApiData = await axios.post(url, payLoad);
    orderobject = deleteApiData.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(orderobject);
};

export const removemultiplemovements = async (payLoad) => {
  let orderobject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/removemultiplemovements";
    const deleteApiData = await axios.post(url, payLoad);
    orderobject = deleteApiData.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(orderobject);
};

export const getTerminalbylatlong = async (data) => {
  let locationobject = [];
  try {
    let payLoad = {
      longitude: data?.longitude,
      latitude: data?.latitude,
      searchtext: data?.searchtext,
      distance: data?.distance ?? 20,
    };
    const url = BaseConfig.ApiEndPoint + "/getlocationbylatlong";
    const latlngApiData = await axios.post(url, payLoad);
    locationobject = latlngApiData.data.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return Promise.resolve(locationobject);
};

export const createReturn = async (payload, planId) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/${planId}/return`;
    const response = await axios.post(url, payload);
    return response;
  } catch (error) {
    throw err;
  }
};

export const getImapctView = async (input_date, driver_id, trailer_id) => {
  let impactViewApiData = [];
  try {
    let data = {
      inputDate: input_date / 1000,
      driver_id: driver_id,
      trailer_id: trailer_id,
    };
    const url = BaseConfig.ApiEndPoint + "/getimpactview";
    const impactApiData = await axios.post(url, data);
    impactViewApiData = impactApiData.data;
  } catch (error) {
    return Promise.reject("failed");
  }
  return await Promise.resolve(impactViewApiData);
};

export const getAllTerminals = async () => {
  let terminalObject = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/terminals";
    const terminalApiData = await axios.get(url);
    terminalObject = terminalApiData.data.data;
  } catch (err) {
    return Promise.reject(
      "Failure: Unable to retrieve terminal list. Please try refreshing again!"
    ); //"There is a problem on retrieving terminal data. Please try again!");
  }

  return Promise.resolve(terminalObject);
};

export const createEmptyMove = async (payload) => {
  let emptyMove = [];
  try {
    const url = BaseConfig.ApiEndPoint + "/createemptymove";
    emptyMove = await axios.post(url, payload);

    return Promise.resolve(emptyMove);
  } catch (error) {
    return Promise.reject("failed");
  }
};

export const getallcarrierview = async (searchData) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.ApiEndPoint + "/getcarrierview",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        date: searchData.date,
        terminals: searchData.terminals,
      },
    };

    let response = await axios(config);
    const mvmvtStopApiResponse = atob(response.data);

    // Convert binary string to character-number array
    const charData = mvmvtStopApiResponse.split("").map((x) => {
      return x.charCodeAt(0);
    });
    // Turn number array into byte-array
    const binData = new Uint8Array(charData);
    // Use this Code to Decode the String

    //Uncompress API Call//
    let mvmvtStopApiDataUnCompressed = fflate.unzlibSync(binData);
    let mvmvtStopApiDataUnCompressedString = fflate.strFromU8(
      mvmvtStopApiDataUnCompressed
    );
    const mvmvtStopApiDecompressedObject = JSON.parse(
      mvmvtStopApiDataUnCompressedString
    );

    return Promise.resolve(mvmvtStopApiDecompressedObject);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateETA = async (driverId, payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + `/updatedriverlocation/${driverId}`
    const ConfigData = await axios.put(url, payload);
    return ConfigData
  } catch (err) {
    return err;
  }
}

// Driverparking
export const createLocation = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/createdriverparking"
    const ConfigData = await axios.post(url, payload);
    return ConfigData
  } catch (err) {
    return Promise.reject(err);
  }
}

// If there is no pre order scheduled for a driver
export const scheduleSingleOrder = async (driver_id, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/driver/${driver_id}`;
    const responseData = await axios.post(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  }
};

// If there are orders scheduled for a driver
export const scheduleStackedOrders = async (driver_id, plan_id, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/driver/${driver_id}/plan/${plan_id}`;
    const responseData = await axios.patch(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

// For creating bobtail or empty movements
export const deleteMovementFromPlan = async (driverId, planId, movementId,data) => {  
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/remove-move/driver/${driverId}/plan/${planId}/move/${movementId}`;
    var config = {
      method: "patch",
      url,
      headers: {
        "Content-Type": "application/json",
      },
      data:data ?? {}
    };
    let response = await axios(config);
    return response;
  } catch (err) {
    throw err;
  };
};

// For creating bobtail or empty movements
export const createBobtailOrEmptyMovements = async (driver_id, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/add-move/driver/${driver_id}`;
    const responseData = await axios.post(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

// For adding a trailer to an order
export const addTrailer = async (driverId, planId, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/add-trailer/driver/${driverId}/plan/${planId}`;
    const responseData = await axios.patch(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

// For removing a trailer from an order
export const removeTrailer = async (driverId, planId, payload) => {
  try {
    const url = `${BaseConfig.PlanningApiEndPoint}/remove-trailer/driver/${driverId}/plan/${planId}`;
    const responseData = await axios.post(url, payload);
    return responseData;
  } catch (err) {
    throw err;
  };
};

export const createSplit = async (payload) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint + "/split",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//unsplit
export const createUnSplit = async (payload) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint +"/unsplit",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

//change Movement
export const editMovements = async (payload,id) => {

  try {
    var config = {
      method: "patch",
       url: BaseConfig.PlanningApiEndPoint +"/movement"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

//Edit Stops
export const editStops = async (payload,id) => {

  try {
    var config = {
      method: "patch",
       url: BaseConfig.PlanningApiEndPoint + "/stop"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

//change Plan Time By Startshift
export const changePlanTimebystartshift = async (payload,id,data) => {

  try {
    var config = {
      method: "patch",
       url: BaseConfig.PlanningApiEndPoint+ "/plan"+ `/${id}`+"/starttime"+`/${payload}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data ?? {}
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const getDriverHosClock = async (id) => {
  try {
    var config = {
      method: "get",
       url: BaseConfig.ApiEndPoint + "/driverhosclock"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      }
    };
    let response = await axios(config);
    return Promise.resolve(response?.data?.data);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const addTrailerToorderfortrailerview = async (id,payload) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.PlanningApiEndPoint+ "/addremovetrailer/addtrailer"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data:payload
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const updateTrailerdatafortrailerview = async (id,payload) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.PlanningApiEndPoint + "/addremovetrailer/updatetrailer"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data:payload
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const removeTrailerfromorderfortrailerview = async (id,payload) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.PlanningApiEndPoint + "/addremovetrailer/removetrailer"+ `/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data:payload
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const addOrderComments = async (data) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint + "/addorderComments",
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const getNearByLocation = async (data) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint+ "/location/search",
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const addNewLocation = async (data) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint+ "/location/addLocation",
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const sendXmitToLoadmaster = async (data) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.PlanningApiEndPoint+ "/xmit",
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const changeTrailerLocation = async (data) => {
  try {
    var config = {
      method: "post",
      url: BaseConfig.PlanningApiEndPoint+ '/trailer/location/change',
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let response = await axios(config);
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const checkForUnsplit = async (payload) => {
  try {
    const url = BaseConfig.ApiEndPoint + "/unsplitCheck";
    const res = await axios.post(url, payload);

    return Promise.resolve(res?.data?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const checkForActual = async (id,data) => {
  try {
    const config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint + "/edit_actuals/check/stop/"+ id,
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let res = await axios(config);
    
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePlanningTerminalOfDriver = async (data) => {
  try {
    var config = {
      method: "post",
       url: BaseConfig.ApiEndPoint + "/updateplanningTerminal",
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let response = await axios(config);
    return Promise.resolve(response?.data?.data);
  } catch (error) {
    return Promise.reject(error);
  }

};

export const getDurationAndDistance = async (data) => {
  try {
    const config = {
      method: "post",
       url: BaseConfig.PlanningApiEndPoint + "/pcmiller/distance/duration",
      headers: {
        "Content-Type": "application/json",
      },
      data
    };
    let res = await axios(config);
    
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
};